*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

body{

    font-family: sans-serif;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);

}

.main-container{
    width: 100%;
    // height: 110vh;

    .anime-content{
        display: block;
        margin: 50px auto;
        width: 35%;
        // height: 105vh;
        background-color: white;
        border-radius: 35px 0px 35px 0px;
        overflow: hidden;
        // border: 1px solid red;
        background-color: rgb(37, 37, 37);
    }
    .heading{
        text-align: center;
        padding: 10px;
        h1{
            background-image: linear-gradient(45deg, #FA8BFF 0%, #2BD2FF 52%, #2BFF88 90%);        
            background-clip: text;
            color: transparent;
            font-size: 30px;
            text-decoration: underline red;
            text-underline-offset: 10px;
            padding: 10px;
        }

    }
    .animelists{
        // margin-top: 10px;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding: 1px;
        img{
            width: 100%;
            height: 50vh;
        }
        h3{
            color: #2BD2FF;
        }
        .buttons{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 30px;
            justify-content: center;
            button{
                width: 40%;
                padding: 10px;
                font-size: 20px;
                border: none;
                outline: none;
                color: white;
                background-image: linear-gradient(to right, #8e2de2, #4a00e0);
                border-radius: 10px 0px 10px 0px;
                cursor: pointer;
                transition: all 0.8s;
            }
            button:hover{
                color: black;
                background-image: radial-gradient(circle farthest-side, #fceabb, #f8b500);
            }
        }
    }

}